const DATA = {
  menuHome: 'Начало',
  menuAbout: 'За нас',
  menuVouchers: 'Ваучери',
  menuTranslations: 'Преводи',
  menuLegalisation: 'Легализация',
  menuCourses: 'Курсове',
  menuPrices: 'Цени',
  menuContacts: 'Контакти',

  more: 'Повече',

  home: 'Добре дошли на страницата на Аскора ООД Вашия надежден и компетентен партньор в осъществяването на успешна чуждоезикова комуникация. Ние приемаме себе си като посредници между езици и култури, които ще Ви помогнат да постигнете своите комуникативни цели.',
  home1: 'Днес българските граждани имат възможност да пътуват, живеят и да работят в чужбина. В професионалната си дейност те могат да осъществяват контакти и съвместна дейност с чуждестранни фирми. С преводаческите си услуги Aскора ООД е на разположение на своите клиенти при подготовката и превода на необходимите за тази цел документи',
  home2: 'Доброто владеене на чужди езици е ключова компетентност от съществено значение за успеха в съвременния свят и на трудовия пазар. Ние ще ви помогнем да придобиете необходимите чуждоезикови знания и умения.',
  home3: 'Да проведете своето образование в чужбина е смела и сериозна крачка, която трябва да обмислите внимателно. За да направите най-добрия избор, свържете се с нас – ние предлагаме консултации и помощ при кандидатстване в чуждестранни учебни заведения.',

  about: ' Аскора ООД е създадена през 1999 година. Дейността на фирмата се осъществява в следните направления:',
  aboutList : 'Преводи и легализация на документи;',
  aboutList1 : 'Кандидатстване в чуждестранни университети;',
  aboutList2 : 'Признаване на дипломи от чужбина в съответните ведомства в България;',
  aboutList3 : 'Подготовка на документи за работа в чужбина на медицински кадри;',
  aboutList4 : 'Подготовка на документи за получаване право на пребиваване в България и придобиване на българско гражданство;',
  aboutList5 : 'Попълване на формуляри от и за чужбина: за семейни помощи за деца, за изчисляване на данъци, за получаване на обезщетения за безработица и др.',
  about1: ' Обърнете се към нас, когато имате нужда от нашите услуги, и ще станете част от многобройните доволни клиенти, които от години се доверяват на Аскора ООД.',
  aboutMore: 'Тук ще научите повече за екипа на Аскора',

  translationMore: 'Повече за преводите на Аскора',
  translationList: 'Високо качество, приемливи цени, прецизно спазване на обещаните срокове, конфендициалност и сигурност са водещите принципи в нашата дейност, като даваме предимство на качеството пред бързината. Основната ни цел е да удовлетворим нуждите на нашите клиенти.',
  translationList1: 'Като професионална агенция за преводи Аскора ООД има сключен договор с Министерството на външните работи за извършване на официални преводи на документи и други книжа от български на чужди езици и от чужди езици на български.',

  legalisationMore: 'Инфо за легализация на преводи',
  legalisationList: 'Аскора ООД урежда всички формалности по заверката и легализацията на Вашите документи във всички държавни институции и посолства в Република България.',
  legalisationList1: 'Предлагаме също така услуги по заверката на легализираните документи в посолствата и консулските служби на чуждите държави в България.',
  legalisationList2: 'Цената на легализирания документ следователно се формира като сума от стойността на превода, куриерската услуга и държавната такса за заверка и апостил в съответната институция.',

  pricesMore: 'Научи повече за нашите цени',
  pricesList: 'Преводът не е търговско изделие, което предварително съществува в напълно готов вид.',
  pricesList1: 'Всеки отделен текст представлява уникат, чиято форма и най-вече съдържание трябва да бъдат “прехвърлени” на съответния език.',
  pricesList2: 'Поръчвайки превод, Вие купувате времето и професионализма на отделен преводач или екип от преводачи.',

  contactsAddress: '6600 Kърджали <br /> ул."Републиканска" 41',
  contactsFb:'Аскора във фейсбук',
  contactsMail: 'Пишете ни:',
  contactsMail1: 'askora@mbox.contact.bg',
  contactsMail2: 'binarada1@abv.bg',

  rada: 'Радка Славчева',
  aboustRada: 'Завършила Гимназия с преподаване на немски език “Вилхелм Пик” в Бургас и немска филология в Софийски университет “Климент Охридски”. Дългогодишен преподавателски стаж във Филиал “Любен Каравелов” на Пловдивски университет „Паисий Хилендарски”, Гимназия с преподаване на чужди езици “Христо Ботев” в Кърджали и Езикова школа “Аскора”. Специализации в Хале, Лайпциг, Бранденбург, Манхайм, Мюнхен, Хамбург, Виена, Москва. Преводач от/на немски език от 1990 г.',
  bina: 'Добрина Кючукова',
  aboutBina: 'Завършила Гимназия с преподаване на френски език “Ромен Ролан” в Стара Загора и френска филология в Софийски университет “Климент Охридски”. Дългогодишен преподавателски стаж във Филиал “Любен Каравелов” на Пловдивски университет „Паисий Хилендарски”, Гимназия с преподаване на чужди езици “Христо Ботев” в Кърджали и Езикова школа “Аскора”. Многократни специализации в Безансон и Париж, Франция. Преводач от/на френски език от 1990 г.',
  toni: 'Антония Петрова-Такова',
  aboutToni: 'Завършила Гимназия с преподаване на английски език „Христо Ботев” в Кърджали, специалност „Обща агрономия” в Аграрен университет Пловдив и магистърска програма „Финанси” във Великотърновски университет "Св. св. Кирил и Методий".'
};

export default DATA;
